interface ContextMenuOptions {
    eventName: string;
    payload: any;
    allowDefaultOn?: string[];
}

export function useContextMenu({ eventName, payload, allowDefaultOn = ['INPUT', 'TEXTAREA', 'CONTENTEDITABLE'] }: ContextMenuOptions) {
    const handleContextMenu = (event: MouseEvent) => {
        if (event.target instanceof Element) {
            // Check for regular form controls
            const isFormControl = allowDefaultOn.some((tag) => event.target instanceof Element && (event.target.tagName === tag || event.target.closest(tag.toLowerCase())));

            // Check for Quill editor
            const isQuillEditor = event.target.closest('.ql-editor') !== null;

            if (isFormControl || isQuillEditor) {
                // Allow default browser context menu
                return;
            }
        }

        // Prevent default and show custom menu
        event.preventDefault();
        useEvent(eventName, {
            event,
            ...payload,
        });
    };

    return {
        onContextMenu: handleContextMenu,
    };
}
